.hak-modal {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 5%;
    background-color: white;
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    z-index: 2;
}

.hak-modal-pdf {
    position: absolute;
    top: 3%;
    left: 3%;
    right: 3%;
    bottom: 5%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-modal-password {
    position: absolute;
    top: 30%;
    left: 35%;
    right: 35%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-modal-audio {
    position: absolute;
    top: 30%;
    left: 30%;
    right: 30%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-audioplayer {
    margin-bottom: "100px";
    height: auto;
}

.hak-modal-txt {
    position: absolute;
    top: 5%;
    left: 20%;
    right: 20%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
}

.hak-modal-txt-overflow {
    overflow-y: scroll;
    height: 25em;
}

.hak-modal:focus {
    outline: none;
}

.hak-modal-audio:focus {
    outline: none;
}

.hak-modal-settings:focus {
    outline: none;
}

.hak-modal-pdf:focus {
    outline: none;
}

.hak-modal-txt:focus {
    outline: none;
}

.hak-modal-password:focus {
    outline: none;
}

.hak-modal-header {
    display: flex;
    align-items: center;
    background-color: rgba(179, 179, 179, 255);
    height: 35px;
    color: black;
    cursor: move;
    font-weight: 500;
}

.hak-form {
    padding: 1.5em;
    width: 80%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hak-modal-settings {
    position: absolute;
    top: 30%;
    left: 35%;
    right: 35%;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-settings-inside {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hak-settings-inside-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.error-message {
    color: red;
}

.hak-it-password-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.hak-it-password-inputBox {
    padding: 12px 20px;
    margin: 4px 4px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 35px !important;
}
.hak-modal-video {
    position: absolute;
    top: 10%;
    left: 15%;
    right: 15%;
   
}
.pdf img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:1082px) {
  
}

@media screen and (max-width : 480px){
    .hak-modal-video {
        left: 0%;
        right: 0%;  
    }
}