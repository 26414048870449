
.hak-icon {
    display: flex;
    height: 125px;
    padding: 0px;
}

.hak-icon-label {
    text-align: center;
    word-wrap: break-word;
}

.hak-icon img {
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.hak-icon-container {
    color: white;
}

.hak-icon-ul{
    margin-right: 0px !important;
}

.hak-settings {
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 1em 1em !important;
    width: 100px;
    position: fixed;
    left: 0;
    color: #fff;
    background-color: transparent !important; 
    border-color: rgb(7,127,124) !important;
    cursor: pointer;
    z-index: 99;
}

.hak-poweroff {
    border-radius: 15px !important;
}

.pdf div[alt="Wedding Vision Board"]{
    margin-right : 15px;
}
