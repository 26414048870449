.hak-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(46, 92, 126);
    color: white;
}


.hak-desktop-background {
    height: 18rem;
    width: 18rem;
    margin-bottom: 2rem;
}

.hak-desktop-pwd-input {
    width: 18rem;
    height: 3rem;
    border-radius: 0.2rem;
    border: 0;
    background-color: #dfdfde;
    text-decoration: none;
    text-align: center;
}

.hak-desktop-pwd-input:focus {
    outline-offset: 0px;
    outline: none;
}

.hak-login-button {
    margin-top: 2rem;
    width: 150px;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem;
    background-color: #dfdfde;
    border-radius: 0.5rem;
    border: 0;
}

.text-error {
    color: #f5adad;
    font-weight: bold;
}